import React from "react"
import SEO from "../../../../components/seo"


const NotFoundPage = () => (
  <>
  <div>
    <div className="h-12 w-screen border-b-default border-gray-200">asdas</div>
    <div className="max-w-7xl mx-auto lg:flex">
        <div className="w-70 border-r-default border-gray-200 h-screen px-2 py-4">
            left
        </div>
        <div className="min-w-0 w-full flex-auto lg:static lg:max-h-full lg:overflow-visible h-screen overflow-y-visible">

        </div>
     
    </div>
    
  </div>
  </>
)

export default NotFoundPage
